/* Existing styles (keep them if you need them) */
.App {
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
}

/* ... other existing styles ... */

/* New styles for the landing page */
body {
  font-family: 'Orbitron', sans-serif; /* Adjusted to ensure consistency across the site */
  background: linear-gradient(to right, #000000, #000000); /* Adjusted to a dark gradient */
  color: white;
  margin: 0;
  padding: 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000000;
  padding: 1em;
}

.navbar-brand, .menu div {
  font-size: 1.7em;
  color: #f7f1e3;
}

.menu {
  list-style: none;
  display: flex;
  gap: 1em;
}

.menu div {
  padding: 0.5em 1em;
  background-color: #000000;
  cursor: pointer;
}


.main-content {
  padding: 1.3em;
  text-align: center;
  
}

.main-content h1 {
  font-weight: 300; /* Makes the font lighter than the default */
}



.gradient-text {
  font-size: 1.78rem;
  background: -webkit-linear-gradient(#2c3e50, #e50d0d);
  background: linear-gradient(#8edade, #27f3ca);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  margin-top: 0; /* Adjust if needed to reduce space above */
  line-height: 1.4; /* Increased line height by 40% */
}

.get-access {
  font-family: 'Orbitron', sans-serif; /* Match the site's font style */
  padding: 0.625em 1.25em;
  font-size: 1.25em; /* Adjusted size */
  font-weight: 500;
  cursor: pointer;
  background-color: transparent; /* Make the button background transparent */
  color: #27f3ca; /* Text color */
  border-radius: 20px; /* Rounded edges */
  border: none; /* Remove any default border */
  transition: background-color 0.3s;
  /* Simulate an outline with a solid color that approximates the gradient */
  box-shadow: 0 0 0 3px #27f3ca; /* Adjust this color as needed */
}


.navbar-logo {
  max-width: 50px;
  height: auto;
  margin-right: 20px;
  vertical-align: middle;
}

.navbar-brand {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* Existing styles */

/* ... other existing styles ... */

@media (max-width: 768px) {
  .main-content h1 {
    font-size: 18px; /* Example: specific pixel size */
    font-weight: 300; /* Less bold */
    margin-top: 0;
  }


  .main-content p {
    font-size: 1.4rem; /* Smaller font size for mobile */
    /* You can also adjust the margin if needed to move the text higher or add padding */
  }
}
